/*

loading.js - Created By Patrick Slough

Angular directive to show loading gif while page tweets are loading.

*/

angular.module('twitterConsoleApp')
    .directive('loading', function() {
        return {
            restrict: 'E',
            replace: true,
            // div containing loading.gif file
            template: '<div class="loadingIconHolder text-center"><img ng-src="{{config.assetBaseUrl}}/images/loading.gif"></div>',
            link: function(scope, elem, attrs) {

                // event watcher watching the loading status on scope
                scope.$watch('loading', function(res) {
                    // show if loading is true
                    if (res) {
                        elem.show();
                    } else {
                        elem.hide(); // hide if not
                    }
                });
            }
        };
    });