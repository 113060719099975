/*

messagesTwitterCtrl - Created by Patrick Slough

Angular Controller to access a user's direct messages

*/

angular.module('twitterConsoleApp')
    .controller('messagesTwitterCtrl', ['$scope', 'twApiService', function($scope, twApiService) {

        // intial behavior
        getMessages();
        $scope.$on('refresh', getMessages);

        // function to get Messages
        function getMessages() {
            $scope.loading = true;
            $scope.messages = [];
            twApiService.getMessages().then(function(response) {
                $scope.messages = response;
                $scope.loading = false;
            });
        }

        // function to delete messages and update view correspondingly
        $scope.deleteMessage = function(id) {
            twApiService.deleteMessage(id).then(function(response) {
                // find proper message
                var i = 0;
                while ($scope.messages[i].id_str !== id) {
                    i++;
                }
                // splice it out of the messages array
                $scope.messages.splice(i, 1);
            });
        };
    }]);
