/*

tweetListCtrl.js - Created by Patrick Slough

Angular controller that fetches tweets for Home, Timeline, Hashtag, and User views

*/
angular.module('twitterConsoleApp')
    .controller('tweetListCtrl', ['$scope', '$state', 'twApiService', function($scope, $state, twApiService) {

        var lastTweet, // id_str of last tweet in batch
            tweetArg,	// Extra argument to get tweets from user/hashtag if necessary
            tw; 		// Object that will hold

        // switch to assign functions to TW and other vars based on page state
        switch ($state.current.name) {
        case 'app.twitter.homepage':
            tw = {
                getTweets: twApiService.getHome,
                getMoreTweets: twApiService.getMoreHomeTweets
            };
            break;
        case 'app.twitter.timeline':
            tw = {
                getTweets: twApiService.getTimeline,
                getMoreTweets: twApiService.getMoreTimelineTweets
            };
            break;
        case 'app.twitter.hashtag':
            tw = {
                getTweets: twApiService.getHashtag,
                getMoreTweets: twApiService.getMoreHashtagTweets
            };
            tweetArg = $state.params.term;
            $scope.term = $state.params.term;
            break;
        case 'app.twitter.user':
            tw = {
                getTweets: twApiService.getUserTweets,
                getMoreTweets: twApiService.getMoreUserTweets
            };
            tweetArg = $state.params.username;
            $scope.username = $state.params.username;
            break;
        default:
            break;
        }

        // get wtweets to initialize page
        getTweets();

        $scope.$on('refresh', getTweets);

        function getTweets() {
            $scope.loading = true;
            $scope.moreTweets = false;
            $scope.tweets = [];

            tw.getTweets(tweetArg).then(getTweetsThen);
        }

        $scope.getMoreTweets = function() {
            $scope.loading = true;
            $scope.moreTweets = false;

            tw.getMoreTweets(lastTweet, tweetArg).then(getMoreTweetsThen);
        };

        // code to be executed in the "Then" block of the getTweets Function
        function getTweetsThen(res) {
            $scope.tweets = res.tweets;
            lastTweet = $scope.tweets[$scope.tweets.length - 1].id_str;
            $scope.moreTweets = true;
            $scope.loading = false;
        }

        // code to be exectued in the "Then" block of the getMoreTweets Function
        function getMoreTweetsThen(res) {
            res.tweets = res.tweets.slice(1);
            $scope.tweets = $scope.tweets.concat(res.tweets);
            $scope.moreTweets = !(res.tweets.length < 1);
            lastTweet = $scope.tweets[$scope.tweets.length - 1].id_str;
            $scope.loading = false;
        }
    }]);