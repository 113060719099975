angular.module('config', [])
    .provider('config', function() {
    // Default config
        var defaultConfig = {
                basePath: '/twitter', // Should match <base href=""/> in index.html
                assetBaseUrl:  '',
                translationsUrl:  '/translations/',
                defaultLanguage: 'en',
                // Language key expected format: two letter ISO_639-1 code + region
                // http://www.w3.org/International/articles/language-tags/
                languages: {
                    ar: {
                        name: 'Arabic'
                    },
                    // 'cy': {
                    //     name: 'Welsh'
                    // },
                    de: {
                        name: 'Deutsch'
                    },
                    en: {
                        name: 'English'
                    },
                    en_UK: {
                        name: 'English - UK'
                    },
                    es: {
                        name: 'Spanish'
                    },
                    // 'eu': {
                    //     name: 'Basque'
                    // },
                    fi: {
                        name: 'Finnish'
                    },
                    fr: {
                        name: 'French'
                    },
                    it: {
                        name: 'Italian'
                    },
                    ja: {
                        name: 'Japanese'
                    },
                    ko: {
                        name: 'Korean'
                    },
                    nl: {
                        name: 'Dutch'
                    },
                    pl: {
                        name: 'Polish'
                    },
                    pt: {
                        name: 'Portuguese'
                    },
                    ru: {
                        name: 'Russian'
                    },
                    // 'so': {
                    //     name: 'Somali'
                    // },
                    sv: {
                        name: 'Swedish'
                    },
                    tr: {
                        name: 'Turkish'
                    },
                    vi: {
                        name: 'Vietnamese'
                    },
                    zh_CN: {
                        name: '简体中国',
                        aliases: ['zh']
                    },
                    zh_TW: {
                        name: '中國傳統'
                    }
                },
                availableLanguages: [], // Generated from languages
                languageAliases: {} // Generated from languages
            },
            config = {};

        // Build | dist config based off of global config object
        if (!_.isUndefined(window.config)) {
            _.defaults(config, window.config, defaultConfig);
        } else {
            _.defaults(config, defaultConfig);
        }

        config.availableLanguages = _.map(config.languages, function(obj, key) {
            return key;
        });

        // TODO: Examine the necessity of all this aliasing and consider
        // replacing it with a language/region resolver that's used
        // directly by angular translate
        //
        // https://github.com/angular-translate/angular-translate/blob/a2ac7000f94bcee6dd8e206cab5d7cec3527dd25/src/service/translate.js#L694

        // Maps language_region to language-region.  zh_TW to zh-TW for example.
        function createUnderscoreAlias(name) {
            if (name.indexOf('-') > -1) {
                config.languageAliases[name.replace('-', '_')] = name;
            }
        }

        // Create map of aliases
        _.each(config.languages, function(obj, key) {
            _.each(obj.aliases, function(alias) {
                config.languageAliases[alias] = key;
                createUnderscoreAlias(alias);
            });

            if (key.length === 2) {
                config.languageAliases[key + '*'] = key;
            }

            createUnderscoreAlias(key);
        });

        this.$get = function() {
            return config;
        };
    });
