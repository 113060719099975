angular.module('twitterConsoleApp', [
    'config',
    'ui.router',
    'translations',
    'templates-dist',
    'ngCookies',
    'angular-google-analytics'
])
    .constant('STRINGS', {
        APPLICATION_NAME: 'twitterConsoleApp'
    })
    .config([
        '$locationProvider', '$stateProvider', '$urlRouterProvider', 'AnalyticsProvider', 'configProvider',
        function($locationProvider, $stateProvider, $urlRouterProvider, AnalyticsProvider, configProvider) {
            var config = configProvider.$get();
            if (config.googleAnalytics) {
                AnalyticsProvider.setAccount(config.googleAnalytics);
            } else {
                // else no analytics
                AnalyticsProvider.startOffline(true);
            }

            // We're using ui-router instead of ngRouter
            AnalyticsProvider.trackPages(true);
            AnalyticsProvider.setPageEvent('$stateChangeSuccess');

            $stateProvider
                .state('nolang', {
                    url: '^/',
                    controller: 'langController'
                })
                .state('app', {
                    url: '^/:lang',
                    params: {
                        lang: ''
                    },
                    controller: 'langController',
                    template: '<ui-view/>'
                })
                .state('app.twitter', {
                    abstract: true,
                    templateUrl: '/app/views/navigationWrapper.html',
                    controller: 'navigationTwitterCtrl'
                })
                .state('app.twitter.homepage', {
                    url: '/home',
                    templateUrl: '/app/views/tweetList.html',
                    controller: 'tweetListCtrl'
                })
                .state('app.twitter.timeline', {
                    url: '/timeline',
                    templateUrl: '/app/views/tweetList.html',
                    controller: 'tweetListCtrl'
                })
                .state('app.twitter.messages', {
                    url: '/messages',
                    templateUrl: '/app/views/messages.html',
                    controller: 'messagesTwitterCtrl'
                })
                .state('app.twitter.trending', {
                    url: '/trending',
                    templateUrl: '/app/views/trending.html',
                    controller: 'trendingTwitterCtrl'
                })
                .state('app.twitter.user', {
                    url: '/user/:username',
                    templateUrl: '/app/views/tweetList.html',
                    controller: 'tweetListCtrl'
                })
                .state('app.twitter.hashtag', {
                    url: '/hashtag/:term',
                    templateUrl: '/app/views/tweetList.html',
                    controller: 'tweetListCtrl'
                })
                .state('app.login', {
                    url: '/login',
                    templateUrl: '/app/views/login.html'
                });

            // Redirect unknown routes to root
            $urlRouterProvider
                .when('', '/')
                .otherwise('/');

            // You will probably want to use html5 mode as it gves clean urls,
            // but currently, this breaks if your <base href=""/> is anything
            // besides "/".
            //
            // https://github.com/angular/angular.js/issues/11091
            //
            $locationProvider.html5Mode(false);
        }
    ])
    .run(['$state', 'Analytics', function($state, Analytics) {
    // Top-level click handlers for hashtags and mentions
        $('body').on('click', '.clickableHashtag', function(e) {
            $state.go('app.twitter.hashtag', { term: e.target.textContent });
        });
        $('body').on('click', '.clickableMention', function(e) {
            var un = e.target.textContent.slice(1);
            $state.go('app.twitter.user', { username: un });
        });
        $('body').on('click', '.clickableLink', function(e) {
            try {
                window.external.popup(e.target.href);
                e.preventDefault();
                return false;
            } catch (error) {
                window.open(e.target.href);
                e.preventDefault();
                return false;
            // open in new tab - default behavior on desktop browsers
            }
        });
    }]);

// Gets overridden during by templates.js during build
angular.module('templates-dist', []);
