/*

lang.js - Created by JHTNA Yeoman Generator, Updated by Patrick Slough

Angular Controller for working with Angular Translate - modified to read cookie/query
so as to get proper language from the console.

*/

angular.module('twitterConsoleApp')
    .constant('langRegex', /^[a-z]{2}([-_][A-Z]{2})?$/)
    .controller('langController', [
        '$scope', '$location', '$translate', '$stateParams', '$cookies', 'config', 'STRINGS', 'langRegex', '$rootScope',
        function($scope, $location, $translate, $stateParams, $cookies, config, STRINGS, langRegex, $rootScope) {
            var path,
                newpath,

                // Gets preferred language from query parameter from Cookie created by Node Server
                // Will fall back to english if language is not available
                language = $cookies.language;
            $translate.preferredLanguage(language);

            $rootScope.config = config;

            if (!_.contains(config.availableLanguages, $translate.preferredLanguage())) {
                $translate.preferredLanguage($translate.fallbackLanguage());
            }

            if (!langRegex.test($stateParams.lang)) {
                $location.path('/' + $translate.preferredLanguage() + $location.path());

            } else if (_.contains(config.availableLanguages, $stateParams.lang)) {
                $translate.use($stateParams.lang);

            } else {
                path = $location.path();
                newpath = '/' + path.split('/').splice(2).join('/');
                newpath += (newpath.length > 1 && path[path.length-1] === '/' ? '/' : '');

                $location.path('/' + $translate.preferredLanguage() + newpath);
            }
        }
    ]);
