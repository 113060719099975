/*

trustHtmlFilter.js - Created by Patrick Slough

Angular Filter to trust supplied text as html so links, hashtags
and user mentions can be properly displayed

*/

angular.module('twitterConsoleApp')
    .filter('trustHtml', function($sce) {
        return function(val) {
            return $sce.trustAsHtml(val);
        };
    });