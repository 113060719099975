/*

tweet.js - Created by Patrick Slough

Angular Directive for creating isolate scope for tweet objects and displaying them
with the tweet template. Includes tweet post functions for each tweet

*/
angular.module('twitterConsoleApp')
    .directive('tweet', ['twApiService', '$state', function(twApiService, $state) {
        return {
            restrict: 'E',
            // define external tweet within isolated scope
            scope: {
                tweet: '='
            },
            templateUrl: '/app/directives/tweet.html',
            link: function(scope, element, attrs) {

                // function for favoriting tweet and updating the view as a result
                scope.favorite = function() {
                    // call API favorite function with tweets id
                    twApiService.favorite(scope.tweet.id_str).then(function(response) {
                        // increment tweet count, make "retweeted" true
                        scope.tweet.favorite_count++;
                        scope.tweet.favorited = !scope.tweet.favorited;
                    });
                };

                // function for unfavoriting tweet and updating view
                scope.unfavorite = function() {
                    // Call API unfavorite function
                    twApiService.unfavorite(scope.tweet.id_str).then(function(response) {
                        // decrement count and make favorited false
                        scope.tweet.favorite_count = Math.max(scope.tweet.favorite_count - 1, 0);
                        scope.tweet.favorited = !scope.tweet.favorited;
                    });
                };

                // function for retweeting and updating view
                scope.retweet = function() {
                    // Call API retweet function
                    twApiService.retweet(scope.tweet.id_str).then(function(response) {
                        // Increment retweet count and make retweeted true
                        scope.tweet.retweet_count++;
                        scope.tweet.retweeted = !scope.tweet.retweeted;
                    });
                };
            }
        };
    }]);