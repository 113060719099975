angular.module('translations', [
    'pascalprecht.translate',
    'config'
])
    .config(function($translateProvider, configProvider) {
    // $translateProvider.translations('en', {
    //     HEADLINE: 'Hello there, This is my awesome app!',
    //     INTRO_TEXT: 'And it has i18n support!'
    // });
        var config = configProvider.$get();

        $translateProvider.useStaticFilesLoader({
            prefix: config.translationsUrl,
            suffix: '.json'
        })
            .registerAvailableLanguageKeys(config.availableLanguages, config.languageAliases)
            .fallbackLanguage(config.defaultLanguage)
            .determinePreferredLanguage();
    });
