angular.module('templates-dist', ['/app/directives/tweet.html', '/app/views/app.html', '/app/views/login.html', '/app/views/messages.html', '/app/views/navigationWrapper.html', '/app/views/trending.html', '/app/views/tweetList.html']);

angular.module("/app/directives/tweet.html", []).run(["$templateCache", function ($templateCache) {
  $templateCache.put("/app/directives/tweet.html",
    "<!-- \n" +
    "\n" +
    "tweet.html - Created by Patrick Slough\n" +
    "\n" +
    "HTML Template for tweet items\n" +
    "\n" +
    " -->\n" +
    "\n" +
    "<div class=\"tweetItem\">\n" +
    "    <div class=\"media\">\n" +
    "        <div class=\"media-left morePadding\" ng-show=\"tweet.user.profile_image_url\">\n" +
    "            <img class=\"media-object\" ng-src={{tweet.user.profile_image_url}}>        \n" +
    "        </div>\n" +
    "        <div class=\"media-body\">\n" +
    "            <div ng-show=\"{{tweet.user}}\">\n" +
    "                <div ng-if=\"!$parent.username\">\n" +
    "                    <a ui-sref=\"app.twitter.user({ username: tweet.user.screen_name })\"><b>{{tweet.user.name}}</b></a>\n" +
    "                    &middot; @{{tweet.user.screen_name}} \n" +
    "                    &middot; {{tweet.prettyDate}}\n" +
    "                </div>\n" +
    "                <div ng-if=\"$parent.username\">\n" +
    "                    <b>{{tweet.user.name}}</b>\n" +
    "                    &middot; @{{tweet.user.screen_name}} \n" +
    "                    &middot; {{tweet.prettyDate}}\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div class=\"tweetText\" ng-bind-html=\"tweet.formattedText | trustHtml\">&nbsp;</div>\n" +
    "            <br>\n" +
    "            <div>\n" +
    "                <div>{{tweet.retweet_count}}&nbsp;&nbsp;{{ \"RETWEETS\" | translate }}&nbsp;&nbsp;&nbsp;&middot;&nbsp;&nbsp;&nbsp;{{tweet.favorite_count}}&nbsp;&nbsp;{{ \"FAVORITES\" | translate }}&nbsp;&nbsp;\n" +
    "                </div>\n" +
    "            </div>\n" +
    "        \n" +
    "            <div ng-show=\"tweet.entities.media\" class=\"btn-group pull-left\">\n" +
    "                <button class=\"btn btn-small fixedwidebtn\" ng-click=\"tweet.mediaShown = !tweet.mediaShown\" data-toggle=\"collapse\" data-target=\"#collapseMedia-{{tweet.id_str}}\" aria-expanded=\"false\" aria-controls=\"collapseMedia-{{tweet.id_str}}\">\n" +
    "                    {{tweet.mediaShown ? \"CLOSE\" : \"VIEW_MEDIA\" | translate }}\n" +
    "                </button>\n" +
    "            </div>\n" +
    "            <div ng-switch=\"tweet.favorited\" class=\"btn-group pull-right\">\n" +
    "                <div ng-switch-when=\"true\">\n" +
    "                    <button class=\"btn btn-small fixedwidebtn actionbtn\" ng-click=\"unfavorite()\"><span class=\"glyphicon glyphicon-star favorited\"></span> {{ \"UNFAVORITE\" | translate }}</button>\n" +
    "                </div>\n" +
    "                <div ng-switch-default>\n" +
    "                    <button class=\"btn btn-small fixedwidebtn actionbtn\" ng-click=\"favorite()\"><span class=\"glyphicon glyphicon-star\"></span> {{ \"FAVORITE\" | translate }}</button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <div ng-switch=\"tweet.retweeted\" class=\"btn-group pull-right\">\n" +
    "                <div ng-switch-when=\"true\">\n" +
    "                    <button class=\"btn btn-small fixedwidebtn actionbtn\" disabled><span class=\"glyphicon glyphicon-retweet retweeted\"></span> {{ \"RETWEETED\" | translate }}</button>\n" +
    "                </div>\n" +
    "                <div ng-switch-default>\n" +
    "                    <button class=\"btn btn-small fixedwidebtn actionbtn\" ng-click=\"retweet()\"><span class=\"glyphicon glyphicon-retweet\"></span> {{ \"RETWEET\" | translate }}</button>\n" +
    "                </div>\n" +
    "            </div>\n" +
    "            <br>\n" +
    "            <br>\n" +
    "            <div ng-if=\"tweet.mediaShown\" class=\"collapse pull-left\" id=\"collapseMedia-{{tweet.id_str}}\">\n" +
    "                    <img ng-src={{tweet.entities.media[0].media_url}} class=\"img-responsive\">\n" +
    "            </div>\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <br>\n" +
    "</div>\n" +
    "");
}]);

angular.module("/app/views/app.html", []).run(["$templateCache", function ($templateCache) {
  $templateCache.put("/app/views/app.html",
    "<div class=\"row\">\n" +
    "    <div class=\"col-md-12\">\n" +
    "        <h1>{{\"HELLO_WORLD\" | translate}}</h1>\n" +
    "        <h3><heart/></span> AngularJS</h3>\n" +
    "\n" +
    "        <a ui-sref=\"app.about\">{{\"ABOUT\" | translate}}</a><br>\n" +
    "        <a ui-sref=\"app.homepage\">Homepage Tweets </a><br>\n" +
    "\n" +
    "        <a href=\"/auth/twitter/\" target=\"_self\">Log in with Twitter</a>\n" +
    "\n" +
    "        <div class=\"translationsList\" ui-view=\"translationsList\"></div>\n" +
    "    </div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("/app/views/login.html", []).run(["$templateCache", function ($templateCache) {
  $templateCache.put("/app/views/login.html",
    "<!-- \n" +
    "\n" +
    "login.html - Created by Patrick Slough\n" +
    "\n" +
    "HTML page that prompts user to log in\n" +
    "\n" +
    " -->\n" +
    "<a href=\"/auth/twitter/\" target=\"_self\">\n" +
    "	<div class=\"container-fluid loginScreen vh_height100\">\n" +
    "	    <div class=\"col-sm-3\"></div>\n" +
    "	    <div class=\"col-sm-6\">\n" +
    "	    	<center>\n" +
    "	    		<img class=\"loginImg\" ng-src=\"{{config.assetBaseUrl}}/images/BTN_logo.jpg\">\n" +
    "	    		<br>\n" +
    "	    		<div class=\"loginText\"><h1>{{ \"TOUCH_TO_START\" | translate }}</h1></div>\n" +
    "	    	</center>\n" +
    "	    </div>\n" +
    "	    </div class=\"col-sm-3\"></div>\n" +
    "	</div>\n" +
    "</a>\n" +
    "\n" +
    "");
}]);

angular.module("/app/views/messages.html", []).run(["$templateCache", function ($templateCache) {
  $templateCache.put("/app/views/messages.html",
    "<!-- \n" +
    "\n" +
    "messages.html - Created by Patrick Slough\n" +
    "\n" +
    "HTML page to display a user's direct messages\n" +
    "\n" +
    " -->\n" +
    "\n" +
    "<div class=\"col-sm-10 col-sm-offset-1 tweetCol\">\n" +
    "	<br>\n" +
    "	<loading></loading>\n" +
    "	<div ng-repeat=\"message in messages\">\n" +
    "		<div class=\"tweetItem\">\n" +
    "    		<div class=\"media\">\n" +
    "    		    <div class=\"media-left\" ng-show=\"message.sender.profile_image_url\">\n" +
    "    		        <img class=\"media-object\" ng-src={{message.sender.profile_image_url}}/>        \n" +
    "    		    </div>\n" +
    "    		    <div class=\"media-body\">\n" +
    "    		        <div ng-show=\"{{message.sender}}\">\n" +
    "    		            <b>{{message.sender.name}}</b>\n" +
    "    		            &middot; @{{message.sender.screen_name}} \n" +
    "    		            &middot; {{message.sender.prettyDate}}\n" +
    "    		        </div>\n" +
    "    		        <div>{{ \"TO\" | translate }}: {{message.recipient.name}}</div>\n" +
    "    		        <div>\n" +
    "    		            <div ng-bind-html=\"message.formattedText | trustHtml\"></div>\n" +
    "    		        </div>\n" +
    "    		    </div>\n" +
    "    		    <div>\n" +
    "    		        <button class=\"btn btn-small fixedwidebtn pull-right\" ng-click=\"deleteMessage(message.id_str)\">{{ \"DELETE\" | translate }}</button>\n" +
    "    		    </div>\n" +
    "    		</div>\n" +
    "    		<br>\n" +
    "		</div>\n" +
    "		<br>\n" +
    "	</div>\n" +
    "	<br>\n" +
    "</div>");
}]);

angular.module("/app/views/navigationWrapper.html", []).run(["$templateCache", function ($templateCache) {
  $templateCache.put("/app/views/navigationWrapper.html",
    "<!-- \n" +
    "\n" +
    "navigationWrapper.html - Created by Patrick Slough\n" +
    "\n" +
    "Angular template for navigation bar header for each (non-login) page\n" +
    "\n" +
    " -->\n" +
    "\n" +
    "\n" +
    "<div class=\"container-fluid\">\n" +
    "	<nav class=\"navbar navbar-custom navbar-fixed-top\">\n" +
    "		<div class=\"navbar-header\">\n" +
    "			<span class=\"navbar-brand\"><img ng-src=\"{{config.assetBaseUrl}}/images/BTN_logo_sm.jpg\"></span>\n" +
    "		</div>\n" +
    "		<div class=\"collapse navbar-collapse\">\n" +
    "			<ul class=\"nav navbar-nav navbar-left\">\n" +
    "				<li ng-class=\"{activeTab: state.current.name == 'app.twitter.homepage'}\"\n" +
    "         			><a ui-sref=\"app.twitter.homepage\"><span 	class=\"glyphicon glyphicon-home\"></span\n" +
    "          			> {{\"HOME\" | translate}}</a></li>\n" +
    "				<li ng-class=\"{activeTab: state.current.name == 'app.twitter.timeline'}\"\n" +
    "					><a ui-sref=\"app.twitter.timeline\"><span 	class=\"glyphicon glyphicon-user\"></span\n" +
    "					> {{\"ME\" | translate}}</a></li>\n" +
    "				<li ng-class=\"{activeTab: state.current.name == 'app.twitter.messages'}\"\n" +
    "					><a ui-sref=\"app.twitter.messages\"><span 	class=\"glyphicon glyphicon-envelope\"></span\n" +
    "					> {{\"MESSAGES\" | translate}}</a></li>\n" +
    "				<li ng-class=\"{activeTab: state.current.name == 'app.twitter.trending'}\"\n" +
    "					><a ui-sref=\"app.twitter.trending\"><span 	class=\"glyphicon glyphicon-arrow-up\"></span\n" +
    "					> {{\"TRENDING\" | translate}}</a></li>\n" +
    "			</ul>\n" +
    "			<ul class=\"nav navbar-nav navbar-right\">\n" +
    "				<li class=\"navDark\"><a ng-click=\"$broadcast('refresh')\" href=\"javascript:;\"\n" +
    "					><span class=\"glyphicon glyphicon-refresh\"></span\n" +
    "					> 	{{\"REFRESH\" | translate }}</a></li>\n" +
    "				<li class=\"navDark\"><a href=\"/auth/logout\" target=\"_self\"\n" +
    "					><span class=\"glyphicon glyphicon-remove\"></span\n" +
    "					> 	{{\"SIGN_OUT\" | translate}}</a></li>\n" +
    "			</ul>\n" +
    "		</div>\n" +
    "	</nav>\n" +
    "\n" +
    "\n" +
    "	<div class=\"row tweetContainer\" ng-class=\"{ 'trending' : state.current.name === 'app.twitter.trending'}\">\n" +
    "    	<ui-view/>\n" +
    "	</div>\n" +
    "\n" +
    "</div>\n" +
    "");
}]);

angular.module("/app/views/trending.html", []).run(["$templateCache", function ($templateCache) {
  $templateCache.put("/app/views/trending.html",
    "<!-- \n" +
    "\n" +
    "trending.html - Created by Patrick Slough\n" +
    "\n" +
    "HTML page for displaying trending terms and tweets\n" +
    "\n" +
    " -->\n" +
    "<div class=\"col-sm-3 trendCol\">\n" +
    " 	<H2>{{ \"TRENDS\" | translate}}</H2>\n" +
    " 	<div ng-repeat=\"trend in trending.trends\">\n" +
    "     	<div ng-click=\"getHashtag(trend.name)\">\n" +
    "     		<span class=\"clickableTrend\">{{trend.name}}</span>\n" +
    "		</div>\n" +
    " 	</div>\n" +
    " 	<br>\n" +
    "</div>		\n" +
    "\n" +
    "<div class=\"col-sm-9\">\n" +
    "	<div class=\"col-sm-12 tweetCol\">\n" +
    "		<H2>{{ \"RESULTS_FOR_TREND\" | translate:{trend: currentSearch} }}</H2>\n" +
    "		<br>\n" +
    "	</div>\n" +
    "	<div class=\"col-sm-12 tweetCol\">\n" +
    "		<br>\n" +
    "		\n" +
    "		<div ng-repeat=\"tweet in hashtagTweets\">\n" +
    "			<tweet tweet=\"tweet\"></tweet>\n" +
    "			<br>\n" +
    "		</div>\n" +
    "\n" +
    "		<loading></loading>\n" +
    "\n" +
    "		<br>\n" +
    "		<div class=\"text-center\" ng-hide=\"loading\">\n" +
    "      		<div ng-show=\"moreHashtagTweets\">\n" +
    "      	 		<div class=\"btn moreTweetButton\" ng-click=\"getMoreHashtagTweets()\"><span class=\"glyphicon glyphicon-plus\"></span></div>\n" +
    "      		</div>\n" +
    "     			 <div ng-show=\"moreHashtagTweets===false\">\n" +
    "      	 		{{ \"SORRY_NO_MORE_TWEETS_TO_DISPLAY\" | translate }}\n" +
    "      		</div>\n" +
    "  		</div>\n" +
    "  		<br>\n" +
    "  	</div>\n" +
    "</div>\n" +
    "");
}]);

angular.module("/app/views/tweetList.html", []).run(["$templateCache", function ($templateCache) {
  $templateCache.put("/app/views/tweetList.html",
    "<div class=\"col-sm-10 col-sm-offset-1 tweetCol\" ng-show=\"term\">\n" +
    "    <h2> {{ \"RESULTS_FOR_TREND\" | translate:{trend: term} }} </h2>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"col-sm-10 col-sm-offset-1 tweetCol\" ng-show=\"username\">\n" +
    "    <h2>{{ \"TWEETS_FROM_USER\" | translate:{user: username} }}</h2>\n" +
    "</div>\n" +
    "\n" +
    "<div class=\"col-sm-10 col-sm-offset-1 tweetCol\">\n" +
    "    <br>        \n" +
    "	<div ng-repeat=\"t in tweets\">\n" +
    "		<tweet tweet=\"t\"></tweet>\n" +
    "        <br>\n" +
    "	</div>\n" +
    "\n" +
    "    <loading></loading>\n" +
    "\n" +
    "    <div class=\"text-center\" ng-hide=\"loading\">\n" +
    "        <div ng-show=\"moreTweets===true\">\n" +
    "            <div class=\"btn  moreTweetButton\" ng-click=\"getMoreTweets()\"><span class=\"glyphicon glyphicon-plus\"></span></div>\n" +
    "        </div>\n" +
    "        <div ng-show=\"moreTweets===false\">\n" +
    "            {{ \"SORRY_NO_MORE_TWEETS_TO_DISPLAY\" | translate}}\n" +
    "        </div>\n" +
    "    </div>\n" +
    "    <br>\n" +
    "</div>\n" +
    "<br>");
}]);
