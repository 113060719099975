/*

twApiService.js - Created by Patrick Slough

Angular factory containing angular Twitter API calls, processing the returned
tweet data as necessary.

*/

angular.module('twitterConsoleApp')
    .factory('twApiService', ['$http', '$translate', function($http, $translate) {

        return {
            // get default homepage tweets
            getHome: function() {
                return $http.get('twapi/home').then(function(response) {
                    return {
                        tweets: processTweets(response.data)
                    };
                });
            },
            // get homepage tweets starting from last one from initial load
            getMoreHomeTweets: function(id) {
                return $http.get('twapi/home/' + id).then(function(response) {
                    return {
                        tweets: processTweets(response.data)
                    };
                });
            },
            // get default timeline tweets
            getTimeline: function() {
                return $http.get('twapi/timeline').then(function(response) {
                    return {
                        tweets: processTweets(response.data)
                    };
                });
            },
            // get next batch of Timeline Tweets
            getMoreTimelineTweets: function(id) {
                return $http.get('twapi/timeline/' + id).then(function(response) {
                    return {
                        tweets: processTweets(response.data)
                    };
                });
            },
            // get top messages
            getMessages: function() {
                return $http.get('twapi/messages').then(function(response) {
                    return processTweets(response.data);
                });
            },
            // delete message with given id
            deleteMessage: function(id) {
                return $http.post('twapi/delete/' + id)
                    .then(function(response) {
                        return 'deleted message ' + id;
                    });
            },
            // get trends from twitter
            getTrending: function() {
                return $http.get('twapi/trending').then(function(response) {
                    return response.data;
                });
            },
            // get latest tweets with a given hashtag
            getHashtag: function(term) {
                return $http.get('twapi/search?q=' + encodeURIComponent(term)).then(function(response) {
                    return {
                        tweets: processTweets(response.data.statuses)
                    };
                });
            },
            // get next batch of hashtag tweets
            getMoreHashtagTweets: function(id, term) {
                return $http.get('twapi/next?q=' + encodeURIComponent(term) + '&max_id=' + id)
                    .then(function(response) {
                        return {
                            tweets: processTweets(response.data.statuses)
                        };
                    });
            },
            // get latest tweets from the given user
            getUserTweets: function(user) {
                return $http.get('twapi/user/' + encodeURIComponent(user))
                    .then(function(response) {
                        return {
                            tweets: processTweets(response.data)
                        };
                    });
            },
            // get next batch of user tweets
            getMoreUserTweets: function(id, user) {
                return $http.get('twapi/user/' + encodeURIComponent(user) + '/' + id)
                    .then(function(response) {
                        return {
                            tweets: processTweets(response.data)
                        };
                    });
            },
            // favorite tweet with given id
            favorite: function(id) {
                return $http.post('twapi/favorite/' + id)
                    .then(function(response) {
                        return 'favorited tweet ' + id;
                    });
            },
            // unfavorite tweet with given id
            unfavorite: function(id) {
                return $http.post('twapi/unfavorite/' + id)
                    .then(function(response) {
                        return 'unfavorited tweet ' + id;
                    });
            },
            // retweet tweet with given id
            retweet: function(id) {
                return $http.post('twapi/retweet/' + id)
                    .then(function(response) {
                        return 'retweeted tweet ' + id;
                    });
            }
        };

        function processTweets(data) {
            _.each(data, function(tweet) {
                // format tweet with proper html where entities exist
                if (tweet.text && tweet.entities) {
                    tweet.formattedText = formatTweetText(tweet.text, tweet.entities);
                }
                // create a more user friendly date view
                if (tweet.created_at) {
                    tweet.prettyDate = formatTime(tweet.created_at);
                }
                // adding media attributes if tweet needs them
                if (tweet.entities && tweet.entities.media) {
                    tweet.mediaShown = false;
                }
            });
            return data;
        }

        // format the tweet text to have proper html formatting
        function formatTweetText(text, entities) {

            var formattedText,
                replacements = getTweetEntities(text, entities);
            // recursively replace text with html for proper linking
            function replace(text, replacements) {
                if (replacements.length) {

                    formattedText = text
                        .replace(
                            replacements[replacements.length - 1][0],
                            replacements[replacements.length - 1][1]
                        );
                    replacements.pop();

                    if (replacements.length) {
                        replace(formattedText, replacements);
                    }
                    return formattedText;
                }
            }

            if (replacements.length) {
                return replace(text, replacements);
            }
            return text;
        }

        function getTweetEntities(text, entities) {

            var replacements = [];
            // text and indices for links
            if (entities.urls && !_.isEmpty(entities.urls)) {
                _.each(entities.urls, function(ent) {
                    var link = [
                        '<a class="clickableLink" href="',
                        ent.url,
                        '">',
                        ent.display_url,
                        '</a>'
                    ].join('');
                    replacements.push([text.substring(ent.indices[0], ent.indices[1]), link]);
                });
            }
            // pictures
            if (entities.media) {
                _.each(entities.media, function(ent) {
                    replacements.push([text.substring(ent.indices[0], ent.indices[1]),'']);
                });
            }
            // hashtags
            if (entities.hashtags) {
                _.each(entities.hashtags, function(tag) {
                    var link = '<span class="clickableHashtag">#' + tag.text + '</span>';
                    replacements.push([text.substring(tag.indices[0], tag.indices[1]), link]);
                });
            }
            // mentions
            if (entities.user_mentions) {
                _.each(entities.user_mentions, function(user) {
                    var link = '<span class="clickableMention">@' + user.screen_name + '</span>';
                    replacements.push([text.substring(user.indices[0], user.indices[1]), link]);
                });
            }
            return replacements;
        }

        // make more user friendly time string
        function formatTime(time) {
            // get language currently being used
            var lang = $translate.use(),
                // create appropriate variables for understanding tweet time
                formatString = 'dd MMM DD HH:mm:ss ZZ YYYY',
                now = moment(),
                tweetTime = moment(time, formatString);

            // change locale to language being used. Now all from(now) calls
            // and calls with format are locale specific
            tweetTime.locale(lang);

            // from(now) if tweet is less than two days old
            if (now.diff(tweetTime, 'hours') < 48) {
                return tweetTime.from(now);
            }
            // otherwise, give localized string with day, month and year

            return tweetTime.format('ll');

        }
    }]);