/*

navigationTwitterCtrl.js - Created by Patrick Slough

Angular controller to simply give the navbar access to the URI state

*/

angular.module('twitterConsoleApp')
    .controller('navigationTwitterCtrl', ['$scope', '$state', function($scope, $state) {
        $scope.state = $state;
    }]);