/*

trendingTwitterCtrl - Created By Patrick Slough

Angular Controller to get the current list of trending terms and their corresponding tweets

*/

angular.module('twitterConsoleApp')
    .controller('trendingTwitterCtrl', ['$scope', 'twApiService', function($scope, twApiService) {

        var lastTweet;

        // function to get a batch of hashtag tweets. Had to be hoisted to work
        $scope.getHashtag = function(term) {
            $scope.loading = true;
            $scope.moreHashtagTweets = false;
            $scope.hashtagTweets = [];
            twApiService.getHashtag(term).then(function(response) {
                $scope.currentSearch = term;
                $scope.hashtagTweets = response.tweets;
                lastTweet= $scope.hashtagTweets[$scope.hashtagTweets.length - 1].id_str;
                $scope.loading = false;
                $scope.moreHashtagTweets = true;
            });
        };

        // initial behavior
        twApiService.getTrending().then(function(response) {
            $scope.trending = response[0];
            $scope.currentSearch = $scope.trending.trends[0].name;
            // get initial tweets to populate trending list
            $scope.getHashtag($scope.currentSearch);
        });

        $scope.$on('refresh', function() {
            $scope.getHashtag($scope.currentSearch);
        });

        // function to get next batch of tweets with the trending hashtag
        $scope.getMoreHashtagTweets = function() {
            $scope.loading = true;
            $scope.moreHashtagTweets = false;
            twApiService.getMoreHashtagTweets(lastTweet, $scope.currentSearch)
                .then(function(response) {
                    response.tweets = response.tweets.slice(1);
                    $scope.hashtagTweets = $scope.hashtagTweets.concat(response.tweets);
                    $scope.moreHashtagTweets = !(response.tweets.length < 1);
                    lastTweet = $scope.hashtagTweets[$scope.hashtagTweets.length - 1].id_str;
                    $scope.loading = false;
                });
        };
    }]);